import { FC, useEffect } from 'react';

import { BugoKakaoShareContent } from '@service/bugo/utils/bugoMessage.util';
import { Icon } from '@shared/components/icons';

type Props = {
  kakaoContent: BugoKakaoShareContent;
  templateId?: number;
};
declare global {
  interface Window {
    Kakao: any;
  }
}
const KakaoShareButton: FC<Props> = ({ kakaoContent, templateId }: Props) => {
  const { ShareKakao } = Icon();
  const kakao = window.Kakao;

  useEffect(() => {
    if (kakao) {
      if (!kakao.isInitialized()) {
        kakao.init(process.env.REACT_APP_KAKAO_SHARE_JAVASCRIPT_KEY);
      }
      kakao.Link.createCustomButton({
        container: '#kakaotalk-sharing-btn',
        templateId: templateId ?? Number(process.env.REACT_APP_KAKAO_SHARE_BUGO_BTN_ID),
        templateArgs: {
          header: kakaoContent.header,
          deathDate: kakaoContent.deathDate,
          deceasedName: kakaoContent.deceasedName,
          bugoTerm: kakaoContent.bugoTerm,
          bugoId: kakaoContent.bugoId,
        },
      });
    }
  }, [kakao, kakaoContent, templateId]);

  return (
    <button id="kakaotalk-sharing-btn">
      <ShareKakao />
    </button>
  );
};

export default KakaoShareButton;
