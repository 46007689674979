import { Link } from 'react-router-dom';
import { ReactComponent as GaonLogo } from 'src/assets/logo/gaon-long-logo.svg';

const navigation = {
  main: [
    { name: '마이페이지', href: '/flower-store/user/mypage', mobile: true },
    { name: '고객센터', href: '/flower-store/faq', mobile: true },
    {
      name: '이용약관',
      href: '/flower-store/terms',
      mobile: false,
      title: 'use',
    },
    {
      name: '개인정보처리방침',
      href: '/flower-store/terms',
      mobile: false,
      title: 'privacy',
    },
    {
      name: '배송 및 환불 규정',
      href: '/flower-store/terms',
      mobile: false,
      title: 'delivery',
    },
  ],
};

export default function Footer() {
  return (
    <footer className="border-t theme-bg-6 theme-border-3">
      <div className="mx-auto max-w-7xl overflow-hidden px-4 pt-2 pb-8 sm:px-6 lg:px-8">
        <div className="center-box">
          <GaonLogo className="h-24" />
        </div>
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div
              key={item.name}
              className={`${item.mobile ? 'block' : 'hidden sm:block'} px-2 py-2 sm:px-5`}
            >
              <Link
                to={item.href + (item.title ? `?title=${item.title}` : '')}
                className={`${
                  item.mobile ? 'block' : 'hidden sm:block'
                } text-sm text-gray-500 hover:text-gray-900`}
              >
                {item.name}
              </Link>
            </div>
          ))}
        </nav>

        <div className="hidden sm:block">
          <p className="mt-4 text-center text-12 text-gray-400">
            사업자 등록번호 : 363-86-02183 &nbsp;| &nbsp;대표 : 김기훈, 정호산
            <br />
            서울특별시 금천구 가산디지털2로 101, 한라원앤원타워 제B동 14층 1402호
            <br />
            CS : 1577-1954&nbsp; |&nbsp; 메일 : smartnanumi@kakao.com
            <br />
            통신판매업신고 : 제 2023-서울금천-0445호
          </p>
        </div>

        <p className="mt-4 text-center text-sm text-gray-400">
          &copy; 2021 GAON prime, Corp. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
