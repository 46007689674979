import { useCallback, useMemo } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { User } from '@shared/api/user/user.interface';
import { queryString } from '@shared/hooks/recoil-query';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { api_ } from '@shared/plugIn/axios';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { CamelRole, LowerRole, UserRole } from '@shared/types';
import { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';

import { tokenSelector, userProfileSelector } from '../atom/auth.atom';
import { userBugoBrandSelector } from './../atom/auth.atom';

export interface GuestDto {
  name: string;
  phoneNumber: string;
  password: string;
}

export const useAuth = () => {
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const [token, setToken] = useRecoilState(tokenSelector);
  //* authenticated 여부
  const guestAuthenticated = useMemo(() => {
    return !_.isNil(token);
  }, [token]);
  const [userProfile, setUserProfile] = useRecoilState(userProfileSelector);

  const userBugoBrand = useRecoilValue(userBugoBrandSelector);

  const isAuthenticated = guestAuthenticated;

  const notificationGenerator = useCallback(
    (errObj: AxiosError) => {
      let errMsgForNoti: string;

      switch (errObj.response?.status) {
        case 401:
          errMsgForNoti = '아이디 혹은 비밀번호(인증정보)를 잘못 입력하셨습니다.';
          break;
        case 403:
          errMsgForNoti = '해당 사이트에 접속할 권한이 없는 회원입니다.';
          break;
        default:
          errMsgForNoti =
            '알 수 없는 에러가 발생했습니다. 지속된다면 고객센터에 문의해주시기 바랍니다.';
      }
      // console.error(err);
      notificateError(notifiacationInstance, errMsgForNoti);
    },
    [notifiacationInstance],
  );
  class DecodedToken {
    roles: UserRole[];
    _id: string;
    constructor(public token: string | null) {
      if (!_.isNil(token)) {
        const { sub, roles, exp } = jwtDecode(token) as any;
        const cTime = new Date().getTime() / 1000;

        if (cTime >= exp) {
          localStorage.removeItem('token');
          window.location.reload();

          this._id = 'unexpected';
          this.roles = [];
          return;
        }

        this._id = sub;
        this.roles = roles;
      } else {
        this._id = 'unexpected';
        this.roles = [];
      }
    }
  }

  const dtoken = new DecodedToken(token);

  /** =======================================================
   * signup
   * ======================================================== */

  const signupByUsernamePasswordCheckPossible = async ({
    username,
  }: {
    username: string;
  }) => {
    const {
      data: { token },
    } = await api_.post('/auth/signup/username-password/possible', {
      username,
    });
    if (token) setToken(token);
    const profile = await getMe(token);
    if (profile) {
      setUserProfile(profile);
    }
    return token;
  };

  const signupByUsernamePassword = async ({
    username,
    password,
    roles,
  }: {
    username: string;
    password: string;
    roles: UserRole[];
  }) => {
    try {
      const res = await api_.post('/auth/signup/username-password', {
        username,
        password,
        roles,
      });
      if (res.status !== 200) {
        notificateError(notifiacationInstance, res.data);
      }
      const {
        data: { token },
      } = res;
      console.log(res);
      if (token) setToken(token);
      const profile = await getMe(token);
      if (profile) {
        setUserProfile(profile);
      }
      return token;
    } catch (err) {
      console.error(err);
    }
  };

  const signupByKakaoCheckPossible = async ({ code }: { code: string }) => {
    const {
      data: { token },
    } = await api_.post('/auth/signup/social/kakao/possible', { code });
    if (token) setToken(token);
    const profile = await getMe(token);
    if (profile) {
      setUserProfile(profile);
    }
    return token;
  };

  //TODO: roles 입력받도록 (user info 입력도 받도록)
  const signupByKakao = async ({ code, roles }: { code: string; roles: UserRole[] }) => {
    const {
      data: { token },
    } = await api_.post('/auth/signup/social/kakao', {
      code,
      roles,
    });
    if (token) setToken(token);
    const profile = await getMe(token);
    if (profile) {
      setUserProfile(profile);
    }
    return token;
  };

  /** =======================================================
   * login
   * ======================================================== */

  const loginByUsernamePassword = async (data: {
    username: string;
    password: string;
  }) => {
    try {
      const {
        data: { token },
      } = await api_.post('/auth/login/username-password', data);
      if (token) setToken(token);
      const profile = await getMe(token);
      if (profile) {
        setUserProfile(profile);
      }
      notificateSuccess(notifiacationInstance, '로그인 되었습니다');

      return token;
    } catch (err) {
      const errObj = err as AxiosError;
      notificationGenerator(errObj);
      throw err;
    }
  };

  const loginByKakao = async (data: any) => {
    const {
      data: { token },
    } = await api_.post('/auth/login/social/kakao', data);
    if (token) setToken(token);
    const profile = await getMe(token);
    if (profile) {
      setUserProfile(profile);
    }
    return token;
  };

  const loginStoreGuest = async (data: any) => {
    try {
      const {
        data: { token },
      } = await api_.post('/auth/login/guest/store', data);
      if (token) setToken(token);
      const profile = await getMe(token);
      if (profile) {
        setUserProfile(profile);
      }

      notificateSuccess(notifiacationInstance, '로그인 되었습니다');
      return token;
    } catch (err) {
      const errObj = err as AxiosError;
      notificationGenerator(errObj);
      throw err;
    }
  };

  const loginBugoGuest = async (data: any) => {
    try {
      const {
        data: { token },
      } = await api_.post('/auth/login/guest/bugo', data);
      if (token) setToken(token);
      const profile = await getMe(token);
      if (profile) {
        setUserProfile(profile);
      }
      notificateSuccess(notifiacationInstance, '로그인 되었습니다');
      return token;
    } catch (err) {
      const errObj = err as AxiosError;
      notificationGenerator(errObj);
      throw err;
    }
  };

  /** =======================================================
   * signup-or-login
   * ======================================================== */
  const signupOrLoginByKakao = async ({
    code,
    roles,
  }: {
    code: string;
    roles: UserRole[];
  }) => {
    try {
      const {
        data: { token },
      } = await api_.post('/auth/signup-or-login/social/kakao', { code, roles });
      if (token) setToken(token);
      const profile = await getMe(token);
      if (profile) {
        setUserProfile(profile);
      }
      notificateSuccess(notifiacationInstance, '로그인 되었습니다');
      return token;
    } catch (err) {
      const errObj = err as AxiosError;
      notificationGenerator(errObj);
      throw err;
    }
  };

  const signupOrLoginStoreGuest = async (
    data: {
      name: string;
      phoneNumber: string;
      password: string;
    },
    silence?: boolean,
  ) => {
    try {
      const {
        data: { token },
      } = await api_.post('/auth/signup-or-login/guest/store', data);
      if (token) setToken(token);
      const profile = await getMe(token);
      if (profile) {
        setUserProfile(profile);
      }
      if (silence) {
        return token;
      }
      notificateSuccess(notifiacationInstance, '로그인 되었습니다');
      return token;
    } catch (err) {
      const errObj = err as AxiosError;
      notificationGenerator(errObj);
      throw err;
    }
  };

  const signupOrLoginBugoGuest = async (data: {
    name: string;
    phoneNumber: string;
    password: string;
  }) => {
    try {
      const {
        data: { token },
      } = await api_.post('/auth/signup-or-login/guest/bugo', data);
      if (token) setToken(token);
      const profile = await getMe(token);
      if (profile) {
        setUserProfile(profile);
      }
      notificateSuccess(notifiacationInstance, '로그인 되었습니다');
      return token;
    } catch (err) {
      const errObj = err as AxiosError;
      notificationGenerator(errObj);
      throw err;
    }
  };

  /** =======================================================
   * user api
   * ======================================================== */
  const linkByKakao = async (dto: { code: string }) => {
    if (!token) return;
    try {
      const { data } = await api_.patch<User>(`/auth/link/social/kakao`, dto);
      return data;
    } catch (error: any) {
      console.error(error);
      console.error(error?.response?.data);
    }
  };

  const getMe = async (token: string | undefined) => {
    if (!token) return;
    try {
      const { data } = await api_.get<User>(
        `auth/me/?${queryString({
          populate: {
            path: 'bugoAgencyAdminDetail.bugoBrands',
          },
        })}`,
      );
      return data;
    } catch (error: any) {
      console.error(error);
      console.error(error?.response?.data);
    }
  };

  const patchMe = async (dto: Pick<User, 'info' | 'adminDetail'>) => {
    if (!token) return;
    try {
      const { data } = await api_.patch<User>(`/auth/me/detail`, dto);
      return data;
    } catch (error: any) {
      console.error(error);
      console.error(error?.response?.data);
    }
  };

  //! 비밀번호 변경
  const patchMePassword = async (newPassword: string) => {
    try {
      await api_.patch('/auth/me/password', { password: newPassword });
      notificateSuccess(notifiacationInstance, '비밀번호 변경이 완료되었습니다.');
      // await logout();
    } catch (err) {
      notificateError(notifiacationInstance, '비밀번호 변경에 실패했습니다.');
      console.error(err);
    }
  };

  const refreshUserProfile = async () => {
    if (token) {
      const userProfile = await getMe(token);
      if (userProfile) {
        setUserProfile(userProfile);
      } else {
        // console.log('redirect 해야함');
      }
    }
  };

  /** =======================================================
   * link
   * ======================================================== */

  /** =======================================================
   * utils
   * ======================================================== */
  const bugoBrandId =
    userProfile?.bugoAgencyAdminDetail?.bugoBrands &&
    userProfile.bugoAgencyAdminDetail.bugoBrands.length > 0
      ? userProfile?.bugoAgencyAdminDetail?.bugoBrands[0]?._id ??
        'agencyAdminHasNoBugoBrand'
      : userProfile?.bugoBrand ?? 'workerHasNoBugoBrand';

  const roles = userProfile?.roles;

  const isAdmin = () => {
    if (roles) {
      return roles.includes(UserRole.Admin);
    } else {
      return false;
    }
  };

  const isBugoAgencyAdmin = () => {
    if (roles) {
      return roles.includes(UserRole.BugoAgencyAdmin);
    } else {
      return false;
    }
  };

  const isBugoAgencyBranchAdmin = () => {
    if (roles) {
      return roles.includes(UserRole.BugoAgencyBranchAdmin);
    } else {
      return false;
    }
  };

  const isBugoAgencyWorker = () => {
    if (roles) {
      return roles.includes(UserRole.BugoAgencyWorker);
    } else {
      return false;
    }
  };

  const isBugoFuneralHome = () => {
    if (roles) {
      return roles.includes(UserRole.BugoFuneralHome);
    } else {
      return false;
    }
  };

  const isBugoCustomer = () => {
    if (roles) {
      return roles.includes(UserRole.BugoCustomer);
    } else {
      return false;
    }
  };

  const isBugoGuest = () => {
    if (roles) {
      return roles.includes(UserRole.BugoGuest);
    } else {
      return false;
    }
  };

  const isStoreSeller = () => {
    if (roles) {
      return roles.includes(UserRole.StoreSeller);
    } else {
      return false;
    }
  };

  const assetRole = () => {
    if (roles) {
      if (roles.includes(UserRole.Admin)) {
        return LowerRole.Admin;
      } else {
        return LowerRole.NonAdmin;
      }
    } else {
      return LowerRole.NonAdmin;
    }
  };

  const bugoRole = () => {
    if (roles) {
      if (roles.includes(UserRole.Admin)) {
        return LowerRole.Admin;
      } else if (roles.includes(UserRole.BugoAgencyAdmin)) {
        return LowerRole.BugoAgencyAdmin;
      } else if (roles.includes(UserRole.BugoAgencyBranchAdmin)) {
        return LowerRole.BugoAgencyBranchAdmin;
      } else if (roles.includes(UserRole.BugoAgencyWorker)) {
        return LowerRole.BugoAgencyWorker;
      } else if (roles.includes(UserRole.BugoFuneralHome)) {
        return LowerRole.BugoFuneralHome;
      } else if (roles.includes(UserRole.BugoCustomer)) {
        return LowerRole.BugoCustomer;
      } else if (roles.includes(UserRole.BugoGuest)) {
        return LowerRole.BugoGuest;
      } else return LowerRole.Public;
    } else {
      return LowerRole.Public;
    }
  };

  const bugoWorkerRole = () => {
    if (roles) {
      if (roles.includes(UserRole.Admin)) {
        return LowerRole.Admin;
      } else if (roles.includes(UserRole.BugoAgencyWorker)) {
        return LowerRole.BugoAgencyWorker;
      } else return LowerRole.Public;
    } else {
      return LowerRole.Public;
    }
  };

  const storeRole = () => {
    if (roles) {
      if (roles.includes(UserRole.Admin)) {
        return LowerRole.Admin;
      } else if (roles.includes(UserRole.StoreCustomer)) {
        return LowerRole.StoreCustomer;
      } else if (roles.includes(UserRole.StoreGuest)) {
        return LowerRole.StoreGuest;
      } else return LowerRole.Public;
    } else {
      return LowerRole.Public;
    }
  };

  const sellerRole = () => {
    if (roles) {
      if (roles.includes(UserRole.Admin)) {
        return LowerRole.Admin;
      } else if (roles.includes(UserRole.StoreSeller)) {
        return LowerRole.StoreSeller;
      } else return LowerRole.Public;
    } else {
      return LowerRole.Public;
    }
  };

  const bugoCamelRole = () => {
    if (roles) {
      if (roles.includes(UserRole.Admin)) {
        return CamelRole.Admin;
      } else if (roles.includes(UserRole.BugoAgencyAdmin)) {
        return CamelRole.BugoAgencyAdmin;
      } else if (roles.includes(UserRole.BugoAgencyWorker)) {
        return CamelRole.BugoAgencyWorker;
      } else if (roles.includes(UserRole.BugoFuneralHome)) {
        return CamelRole.BugoFuneralHome;
      } else if (roles.includes(UserRole.BugoCustomer)) {
        return CamelRole.BugoCustomer;
      } else if (roles.includes(UserRole.BugoGuest)) {
        return CamelRole.BugoGuest;
      } else return CamelRole.BugoGuest;
    } else {
      return CamelRole.BugoGuest;
    }
  };

  const storeCamelRole = () => {
    if (roles) {
      if (roles.includes(UserRole.Admin)) {
        return CamelRole.Admin;
      } else if (roles.includes(UserRole.StoreCustomer)) {
        return CamelRole.StoreCustomer;
      } else if (roles.includes(UserRole.StoreGuest)) {
        return CamelRole.StoreGuest;
      } else return CamelRole.Public;
    }
  };

  const sellerCamelRole = () => {
    if (roles) {
      if (roles.includes(UserRole.Admin)) {
        return CamelRole.Admin;
      } else if (roles.includes(UserRole.StoreSeller)) {
        return CamelRole.StoreSeller;
      } else return CamelRole.Public;
    } else {
      return CamelRole.Public;
    }
  };

  const logout = async () => {
    setToken(null);
    setUserProfile(null);
    window.location.reload();
  };

  const apiLogout = async () => {
    setToken(null);
    setUserProfile(null);
    window.location.reload();
  };

  return {
    roles,
    token,
    guestAuthenticated,
    isAuthenticated,
    userProfile,
    userBugoBrand,
    bugoBrandId,
    signupByUsernamePasswordCheckPossible,
    signupByUsernamePassword,
    signupByKakaoCheckPossible,
    loginByUsernamePassword,
    loginByKakao,
    loginStoreGuest,
    loginBugoGuest,
    linkByKakao,
    patchMe,
    getMe,
    patchMePassword,
    signupOrLoginStoreGuest,
    signupOrLoginBugoGuest,
    signupOrLoginByKakao,
    signupByKakao,
    isAdmin,
    isBugoAgencyAdmin,
    isBugoAgencyWorker,
    isBugoAgencyBranchAdmin,
    isBugoFuneralHome,
    isBugoCustomer,
    isBugoGuest,
    isStoreSeller,
    bugoRole,
    storeRole,
    sellerRole,
    bugoCamelRole,
    bugoWorkerRole,
    storeCamelRole,
    sellerCamelRole,
    assetRole,
    refreshUserProfile,
    logout,
    apiLogout,
    dtoken,
  };
};
