import { FC } from 'react';

import { BugoKakaoShareContent } from '@service/bugo/utils/bugoMessage.util';
import { BottomPopup } from '@shared/components/BottomPopup';
import { Icon } from '@shared/components/icons';
import { checkIOS } from '@shared/utils/miscUtil';
import { RWebShare } from 'react-web-share';

import KakaoShareButton from './KakaoShareButton';

interface Props {
  openId: string;
  content: string;
  title: string;
  kakaoContent: BugoKakaoShareContent;
  linkUrl: string;
}

export const WebShareModal: FC<Props> = function WebShareModal({
  openId,
  content,
  title,
  kakaoContent,
  linkUrl,
}: Props) {
  const { ShareMessage, OtherApps } = Icon();
  const smsContent = content.replace(/\n/g, '%0D%0A');
  return (
    <BottomPopup openId={openId}>
      <div className="w-screen rounded-t-2xl px-9 pt-6 pb-12 theme-bg-1">
        <h2 className="bugo-h1 text-center">공유하기</h2>
        <div className="center-box flex gap-6 pt-6">
          <a href={checkIOS() ? `sms:&body=${smsContent}` : `sms:?body=${smsContent}`}>
            <div className="center-box h-[70px] w-[80px]">
              <div className="flex flex-col items-center justify-center gap-2">
                <ShareMessage className="h-full w-full" />
                <p className="text-sm font-bold leading-5 theme-text-1">문자</p>
              </div>
            </div>
          </a>
          <div className="center-box h-[70px] w-[80px]">
            <div className="flex flex-col items-center justify-center gap-2">
              <KakaoShareButton
                kakaoContent={kakaoContent}
                templateId={kakaoContent.templateId}
              />
              <p className="text-sm font-bold theme-text-1">카카오톡</p>
            </div>
          </div>
          <RWebShare
            data={{
              text: content,
              url: linkUrl,
              title: title,
            }}
            onClick={() => console.log('shared successfully!')}
          >
            <div className="center-box h-[70px] w-[80px]">
              <div className=" flex flex-col items-center justify-center gap-2">
                <OtherApps />
                <p className="text-sm font-bold leading-5 theme-text-1">다른 앱</p>
              </div>
            </div>
          </RWebShare>
        </div>
      </div>
    </BottomPopup>
  );
};
