import { Link } from 'react-router-dom';

const navigation = {
  main: [
    // { name: '마이페이지', href: '/flower-store/user/mypage', mobile: true },
    { name: '고객센터', href: '/flower-store/faq', mobile: true },
    {
      name: '이용약관',
      href: '/flower-store/terms',
      mobile: false,
      title: 'use',
    },
    {
      name: '개인정보처리방침',
      href: '/flower-store/terms',
      mobile: false,
      title: 'privacy',
    },
    {
      name: '배송 및 환불 규정',
      href: '/flower-store/terms',
      mobile: false,
      title: 'delivery',
    },
  ],
};

export default function FooterMari() {
  return (
    <footer className="border-t theme-bg-6 theme-border-3">
      <div className="mx-auto max-w-7xl overflow-hidden px-4 pt-2 pb-8 sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div
              key={item.name}
              className={`${item.mobile ? 'block' : 'hidden sm:block'} px-2 py-2 sm:px-5`}
            >
              <Link
                to={item.href + (item.title ? `?title=${item.title}` : '')}
                className={`${
                  item.mobile ? 'block' : 'hidden sm:block'
                } text-sm text-gray-500 hover:text-gray-900`}
              >
                {item.name}
              </Link>
            </div>
          ))}
        </nav>

        <div className="hidden sm:block">
          <p className="mt-4 text-center text-12 text-gray-400">
            <span className="text-13 leading-6">(주)마리화훼종합센터</span>
            <br />
            사업자 등록번호 : 507-86-02973 &nbsp;| &nbsp;대표 : 신석균
            <br />
            경기도 과천시 중촌로 41(주암동)
            <br />
            CS : 1800-6987&nbsp; |&nbsp; 메일 : mari_gaon@kakao.com
            <br />
          </p>
        </div>

        <p className="mt-4 text-center text-sm text-gray-400">
          &copy; 2021 Mari Flower, Corp. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
