import { DeceasedInfoFormInputsEnum } from '@service/bugo/interfaces/feventAddEdit.interface';
import { FeventOne } from '@shared/api/fevent/fevent.interface';
import { Gender } from '@shared/types';

export const defaultDeceasedInfoInputForm = function (fevent?: FeventOne) {
  const now = new Date();
  const nowAfterTwoDays = new Date();
  nowAfterTwoDays.setDate(now.getDate() + 2);
  DeceasedInfoFormInputsEnum.RegisterNumber;

  if (fevent) {
    return {
      [DeceasedInfoFormInputsEnum.RegisterNumber]: fevent.registerNumber,
      [DeceasedInfoFormInputsEnum.Name]: fevent.deceasedInfo.name,
      [DeceasedInfoFormInputsEnum.Sex]: fevent.deceasedInfo.sex,
      [DeceasedInfoFormInputsEnum.Age]: fevent.deceasedInfo.age,
      [DeceasedInfoFormInputsEnum.BugoTerm]: fevent.deceasedInfo.bugoTerm,
      [DeceasedInfoFormInputsEnum.DeathDate]: fevent.deceasedInfo.death,
      [DeceasedInfoFormInputsEnum.EnteranceDate]: fevent.deceasedInfo.enterance,
      [DeceasedInfoFormInputsEnum.CoffinInDate]: fevent.deceasedInfo.coffinIn,
      [DeceasedInfoFormInputsEnum.CoffinOutDate]: fevent.deceasedInfo.coffinOut,
      [DeceasedInfoFormInputsEnum.Cemetery]: fevent.deceasedInfo.cemetery,
      [DeceasedInfoFormInputsEnum.MournerText]: fevent.mournerText,
      [DeceasedInfoFormInputsEnum.FuneralHomeInfo]: fevent.funeralHomeInfo?._id,
      [DeceasedInfoFormInputsEnum.FuneralHomeInfoName]:
        fevent.funeralHomeInfoEmbed?.name ?? fevent.funeralHomeInfo?.name,
      [DeceasedInfoFormInputsEnum.FuneralHomeInfoEmbed]: fevent.funeralHomeInfoEmbed,
      [DeceasedInfoFormInputsEnum.RoomInfo]: fevent.roomInfo?._id ?? '',
      [DeceasedInfoFormInputsEnum.RoomInfoName]: fevent.roomInfo?.name ?? '',
      [DeceasedInfoFormInputsEnum.RoomInfoEmbedName]: fevent.roomInfoEmbed?.name,
      [DeceasedInfoFormInputsEnum.DpImage]: fevent.deceasedInfo.dpImage?._id,
      [DeceasedInfoFormInputsEnum.BugoBrandAgencyIndex]: fevent.bugoBrandAgencyIndex,
      [DeceasedInfoFormInputsEnum.KakaoShareTemplateId]: fevent.kakaoShareTemplateId,
    };
  } else {
    return {
      [DeceasedInfoFormInputsEnum.DeathDate]: { date: now, timeDefined: false },
      [DeceasedInfoFormInputsEnum.EnteranceDate]: { date: null, timeDefined: false },
      [DeceasedInfoFormInputsEnum.CoffinInDate]: { date: null, timeDefined: false },
      [DeceasedInfoFormInputsEnum.CoffinOutDate]: {
        date: nowAfterTwoDays,
        timeDefined: false,
      },
      [DeceasedInfoFormInputsEnum.Sex]: Gender.Male,
      [DeceasedInfoFormInputsEnum.KakaoShareTemplateId]: Number(
        process.env.REACT_APP_KAKAO_SHARE_BUGO_BTN_ID,
      ),
    };
  }
};
