import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { BugoNavBar } from '@service/bugo/components/BugoNavBar';
import BugoEnquiryPage from '@service/bugo/page/BugoEnquiryPage';
import BugoFAQPage from '@service/bugo/page/BugoFAQPage';
import { BugoLoginPage } from '@service/bugo/page/BugoLoginPage';
import { BugoSignupPage } from '@service/bugo/page/BugoSignupPage';
import { CiAuthNavigatorPage } from '@service/bugo/page/CiAuthNavigatorPage';
import ErrorPage from '@service/bugo/page/ErrorPage';
import { FindMyIdPage } from '@service/bugo/page/FindMyIdPage';
import { SignupInitPage } from '@service/bugo/page/SignupInitPage';
import { TermsAgreementPage } from '@service/bugo/page/TermsAgreementPage';
import { TermsPage } from '@service/bugo/page/TermsPage';
import UnitTestPage from '@service/bugo/page/UnitTestPage';
import { bugoNavBarUseAtom } from '@service/bugo/state';
import FooterMari from '@shared/components/FooterMari';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import BugoAuthRouter from './BugoAuthRouter';
import BugoIdRouter from './BugoIdRouter';
import BugoMemberIdRouter from './BugoMemberIdRouter';

export const BugoRouter: FC = function BugoRouter() {
  const { pathname, search } = useLocation();
  const bugoNavBarUse = useRecoilValue(bugoNavBarUseAtom);
  const notUseNavBar =
    pathname.endsWith('login') || pathname.endsWith('error') || !bugoNavBarUse;

  const { isAuthenticated } = useAuth();

  if (
    pathname !== '/login' &&
    pathname !== '/' &&
    pathname !== '/signup/init' &&
    !isAuthenticated
  ) {
    sessionStorage.setItem('redirectUrlAfterBugoLogin', pathname + search);
  }

  //! 예외 케이스 정의가 필요함
  return (
    <div className="max-w-inherit">
      <BugoNavBar />
      <div className={`max-w-inherit ${notUseNavBar ? '' : 'pt-14'}`}>
        <Routes>
          <Route path="faq" element={<BugoFAQPage />} />
          <Route path="enquiry" element={<BugoEnquiryPage />} />
          <Route path="find-id-password" element={<FindMyIdPage />} />
          <Route path="signup/terms-agreement" element={<TermsAgreementPage />} />
          <Route path="check-ci" element={<CiAuthNavigatorPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="unit-test" element={<UnitTestPage />} />
          <Route path="error" element={<ErrorPage />}></Route>
          <Route path="signup/init" element={<SignupInitPage />} />
          <Route path="bugo/:bugoId/*" element={<BugoIdRouter />} />
          <Route path="member/:memberId/*" element={<BugoMemberIdRouter />} />
          {/* logout required */}
          <Route
            path="login"
            element={!isAuthenticated ? <BugoLoginPage /> : <Navigate to="/" />}
          />
          <Route
            path="signup"
            element={!isAuthenticated ? <BugoSignupPage /> : <Navigate to="/" />}
          />

          {/* login required */}
          <Route
            path="*"
            element={isAuthenticated ? <BugoAuthRouter /> : <Navigate to="/login" />}
          />
        </Routes>
      </div>
      <div className="mt-16">
        <FooterMari />
      </div>
    </div>
  );
};
